import { actionTypes } from '../../redux/actions/main'

import {
	ESTABELECIMENTO_ID
} from '../../config'

const initialState = {
	userCards: null,
	methodAccess: null,
	selectedProduct: null,
	store: {
		id: ESTABELECIMENTO_ID,
		oldSelectedProduct: null,
		oldSelectedCategory: null
	},
	order: null,
	cart: {
		products: null
	},
	removedcart: {
		products: null
	},
	stores: {
		list: [],
		pagination: {
			offset: 0,
			length: 20
		}
	},
	modality: {
		id: 4
	},
	login: {
		ddi: 55,
		country: 'brasil'
	},
	notification: null,
	loading: ["store"],
	scheduling: null,
	userScheduling: null,
	searchProductCategory: null,
	userCard: null,
	card: null,
	userHistory: {
		navigation: []
	},
	gtm: {
		events: null
	},
	params: null,
	device: null,
	orders: {
		orders: null,
		retry: true
	},
	orderHistory: {
		orders: null,
		status: null,
		message: null,
		pagination: {
			offset: 0,
			length: 10
		}
	},
	pagination: {
		offset: 0
	},
	receipt: {},
	receiptStatus: {},
	thing: null,
	coupon: '',
	couponField: '',
	contactUs: { message: '' },
	openCart: false,
	URLParameters: '',
	storeSettings: {},
	scroll: { x: 0, y: 0 },
	antifraudCheck: false,
	directAccess: false,
}

const mainReducer = (state = initialState, action) => {
	const localStorageData = JSON.parse(localStorage.getItem('main')) || {}
	const loadedState = {
		...state,
		...localStorageData,
		scroll: state.scroll
	}

	const newState = {
		value: loadedState
	}

	switch (action.type) {
		case actionTypes.SET_DEVICE:
			Object.assign(newState, {
				value: {
					...newState.value,
					device: action.payload
				}
			})

			break

		case actionTypes.SET_PAGINATION:
			Object.assign(newState, {
				value: {
					...newState.value,
					pagination: action.payload
				}
			})

			break

		case actionTypes.SET_METHOD_LOGIN:
			Object.assign(newState, {
				value: {
					...newState.value,
					methodAccess: action.payload
				}
			})

			break
		case actionTypes.SET_DIRECT_ACCESS:
			Object.assign(newState, {
				value: {
					...newState.value,
					directAccess: action.payload
				}
			})

			break
		case actionTypes.SET_CATEGORIES:
			Object.assign(newState, {
				value: {
					...newState.value,
					categories: action.payload
				}
			})

			break

		case actionTypes.SET_OPEN_CART:
			Object.assign(newState, {
				value: {
					...newState.value,
					openCart: action.payload
				}
			})

			break

		case actionTypes.SET_PRODUCT_CATEGORY:
			Object.assign(newState, {
				value: {
					...newState.value,
					store: {
						...newState.value.store,
						searchProducts: action.payload
					}
				}
			})

			break

		case actionTypes.SET_SEARCH:
			Object.assign(newState, {
				value: {
					...newState.value,
					search: action.payload
				}
			})

			break

		case actionTypes.SET_SEARCH_PRODUCT_CATEGORY:
			Object.assign(newState, {
				value: {
					...newState.value,
					searchProductCategory: action.payload
				}
			})

			break

		case actionTypes.SET_USER_HISTORY:
			const previousNavigation = newState.value.userHistory.navigation ? [...newState.value.userHistory.navigation] : []
			if (newState.value.userHistory.back) {
				previousNavigation.push(newState.value.userHistory.back)
			}
			const navigation = [...previousNavigation]

			Object.assign(newState, {
				value: {
					...newState.value,
					userHistory: {
						...action.payload,
						navigation
					}
				}
			})

			break

		case actionTypes.SET_USER_CARDS:
			Object.assign(newState, {
				value: {
					...newState.value,
					userCards: action.payload
				}
			})

			break

		case actionTypes.SET_USER_CARD:
			Object.assign(newState, {
				value: {
					...newState.value,
					userCard: action.payload
				}
			})

			break

		case actionTypes.SET_USER_CARD_CVV:
			Object.assign(newState, {
				value: {
					...newState.value,
					userCard: {
						...newState.value.userCard,
						cvv: action.payload
					}
				}
			})

			break

		case actionTypes.SET_THING:
			Object.assign(newState, {
				value: {
					...newState.value,
					thing: action.payload
				}
			})

			break

		case actionTypes.SET_STORE_SETTINGS:
			Object.assign(newState, {
				value: {
					...newState.value,
					storeSettings: {
						...action.payload
					}
				}
			})

			break

		case actionTypes.SET_STORE_ID:
			Object.assign(newState, {
				value: {
					...newState.value,
					store: {
						...newState.value.store,
						id: action.payload
					}
				}
			})

			break

		case actionTypes.SET_SCROLL:
			Object.assign(newState, {
				value: {
					...newState.value,
					scroll: {
						...action.payload
					}
				}
			})

			break

		case actionTypes.SET_SCHEDULING:
			Object.assign(newState, {
				value: {
					...newState.value,
					scheduling: action.payload
				}
			})

			break

		case actionTypes.SET_CART:
			Object.assign(newState, {
				value: {
					...newState.value,
					cart: action.payload
				}
			})

			break

		case actionTypes.SET_CONTACT_US:
			Object.assign(newState, {
				value: {
					...newState.value,
					contactUs: {
						...newState.value.contactUs,
						...action.payload
					}
				}
			})

			break

		case actionTypes.SET_CART_COUPON:
			Object.assign(newState, {
				value: {
					...newState.value,
					cart: {
						...newState.value.cart,
						coupon: action.payload
					}
				}
			})

			break

		case actionTypes.SET_SELECTED_CATEGORY:
			const filteredProducts = newState.value && newState.value.categories && newState.value.categories.filter(filteredItem => filteredItem.name === action.payload.name)
			const newProducts = filteredProducts && filteredProducts[0] && filteredProducts[0].products

			Object.assign(newState, {
				value: {
					...newState.value,
					store: {
						...newState.value.store,
						products: newProducts,
						selectedCategory: action.payload
					}
				}
			})


			break

		case actionTypes.REMOVE_SCREEN_HEIGHT:
			Object.assign(newState, {
				value: {
					...newState.value,
					store: {
						...newState.value.store,
						screen: null
					}
				}
			})


			break

		case actionTypes.REMOVE_SELECTED_PRODUCT:
			Object.assign(newState, {
				value: {
					...newState.value,
					oldSelectedProduct: newState.value.selectedProduct,
					selectedProduct: null
				}
			})

			break

		case actionTypes.REMOVE_SELECTED_CATEGORY:
			Object.assign(newState, {
				value: {
					...newState.value,
					store: {
						...newState.value.store,
						oldSelectedCategory: newState.value.store.selectedCategory,
						selectedCategory: {
							...newState.value.store.selectedCategory,
							name: null,
							selectedProductsCategories: false,
							openModalCategory: false
						}
					}
				}
			})

			break

		case actionTypes.REMOVE_SELECTED_OLD_CATEGORY:
			Object.assign(newState, {
				value: {
					...newState.value,
					store: {
						...newState.value.store,
						oldSelectedCategory: null
					}
				}
			})

			break

		case actionTypes.REMOVE_SELECTED_OLD_PRODUCT:
			Object.assign(newState, {
				value: {
					...newState.value,
					store: {
						...newState.value.store,
						oldSelectedProduct: null
					}
				}
			})

			break

		case actionTypes.REMOVE_PRODUCT:
			const products = (newState.value.cart && newState.value.cart.products && newState.value.cart.products.length) ? newState.value.cart.products.filter((filteredItem, index) => filteredItem.name !== action.payload.name) : []

			Object.assign(newState, {
				value: {
					...newState.value,
					store: {
						...newState.value.store,
						oldSelectedProduct: null,
						oldSelectedCategory: null
					},
					cart: {
						...newState.value.cart,
						products
					}
				}
			})

			break

		case actionTypes.SET_PRODUCTS_REMOVED:
			const newProductsB = newState.value.removedcart.products ? newState.value.removedcart.products : []

			newProductsB.push(action.payload)

			Object.assign(newState, {
				value: {
					...newState.value,
					removedcart: {
						...newState.value.removedcart,
						products: newProductsB
					}
				}
			})

			break

		case actionTypes.REMOVE_SELECTED_PRODUCT_ADDITIONAL:
			const selectedAdditionals = (newState.value.store && newState.value.selectedProduct && newState.value.selectedProduct.selectedAdditionals && newState.value.selectedProduct.selectedAdditionals.length && newState.value.selectedProduct.selectedAdditionals.map((mappedItem, index) => {
				if (mappedItem.groupId === action.payload.groupId && mappedItem.calcType === action.payload.calcType) {
					const newItems = mappedItem && mappedItem.items && mappedItem.items.filter(filteredItem => filteredItem.code !== action.payload.code)

					const newMappedItem = {
						...mappedItem,
						items: newItems
					}

					return newMappedItem
				}

				return mappedItem
			})) || []

			Object.assign(newState, {
				value: {
					...newState.value,
					store: {
						...newState.value.store,
						selectedProduct: {
							...newState.value.selectedProduct,
							selectedAdditionals
						}
					}
				}
			})

			break

		case actionTypes.ADD_PRODUCT:
			const newProductsC = newState.value.cart.products ? newState.value.cart.products : []

			newProductsC.push(action.payload)

			Object.assign(newState, {
				value: {
					...newState.value,
					cart: {
						...newState.value.cart,
						products: newProductsC
					}
				}
			})

			break

		case actionTypes.SET_QUANTITY:
			//console.log(action.payload)
			const productsB = newState.value.cart.products ? newState.value.cart.products : []
			const {
				uuid: selectedProductId,
				quantity: quantityIncrement,
				id,
				additionalsEmpty
			} = action.payload

			const newProductsD = { value: productsB }

			if (!quantityIncrement) {
				Object.assign(newProductsD, {
					value: productsB.filter((filteredItem) => (filteredItem.uuid !== selectedProductId))
				})
			}

			if (quantityIncrement) {
				Object.assign(newProductsD, {
					value: productsB.map((mappedItem) => {
						const {
							uuid,
							quantity
						} = mappedItem

						if (uuid === selectedProductId || (additionalsEmpty && mappedItem.id === id)) {
							const newQuantity = quantity + quantityIncrement

							return {
								...mappedItem,
								quantity: newQuantity
							}
						}

						return mappedItem
					})
				})
			}

			Object.assign(newState, {
				value: {
					...newState.value,
					cart: {
						...newState.value.cart,
						products: newProductsD.value
					}
				}
			})

			break

		case actionTypes.SET_ADDITONALS:
			const product = newState.value.selectedProduct ? newState.value.selectedProduct : []
			const accomps = newState.value.selectedProduct && newState.value.selectedProduct.accompaniments ? newState.value.selectedProduct.accompaniments : []
			const {
				code: selectedAccompanimentId,
				quantity: quantityIncrementB,
			} = action.payload

			const newAccompaniments = { value: accomps }

			if (!quantityIncrementB) {
				Object.assign(newAccompaniments, {
					value: accomps.filter((filteredItem) => (filteredItem.id !== selectedAccompanimentId))
				})
			}

			if (quantityIncrementB) {
				Object.assign(newAccompaniments, {
					value: accomps.map((mappedItem) => {
						const extras = mappedItem.Itens
						return {
							...mappedItem,
							Itens: extras.map((item) => {
								const { Codigo, Quantidade } = item

								if (Codigo === selectedAccompanimentId) {
									const newQuantity = Quantidade + quantityIncrementB
									if (newQuantity === 0) {
										return {
											...item,
											Quantidade: newQuantity,
											selectedAccompaniment: false
										}
									}

									return {
										...item,
										Quantidade: newQuantity,
										selectedAccompaniment: true
									}
								}

								return {
									...item,
									selectedAccompaniment: false
								}
							})
						}
					})
				})
			}

			Object.assign(newState, {
				value: {
					...newState.value,
					store: {
						...newState.value.store,
						selectedProduct: {
							...product,
							accompaniments: newAccompaniments.value
						}
					}
				}
			})

			break

		case actionTypes.SET_MODALITY:
			Object.assign(newState, {
				value: {
					...newState.value,
					modality: action.payload
				}
			})

			break

		case actionTypes.SET_LOGIN:
			Object.assign(newState, {
				value: {
					...newState.value,
					login: action.payload
				}
			})

			break

		case actionTypes.SET_NOTIFICATION:
			Object.assign(newState, {
				value: {
					...newState.value,
					notification: action.payload
				}
			})

			break

		case actionTypes.SET_AGGLOMERATION:
			Object.assign(newState, {
				value: {
					...newState.value,
					agglomeration: action.payload
				}
			})

			break

		case actionTypes.SET_LOADING:
			if (!action.payload) {
				Object.assign(newState, {
					value: {
						...newState.value,
						loading: []
					}
				})
			}

			if (typeof action.payload === 'string' && !!action.payload) {
				const loading = newState.value.loading && newState.value.loading.length ? [...newState.value.loading, action.payload] : [action.payload]

				Object.assign(newState, {
					value: {
						...newState.value,
						loading
					}
				})
			}

			if (action.payload === false) {
				Object.assign(newState, {
					value: {
						...newState.value,
						loading: []
					}
				})
			}

			if (typeof action.payload === 'object') {
				const loading = newState.value.loading || []

				Object.assign(newState, {
					value: {
						...newState.value,
						loading: loading.filter(filteredItem => filteredItem !== action.payload.item)
					}
				})
			}

			break

		case actionTypes.SET_USER_SCHEDULING:
			Object.assign(newState, {
				value: {
					...newState.value,
					userScheduling: action.payload
				}
			})

			break

		case actionTypes.SET_SAVED_CARD:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						...action.payload
					}
				}
			})

			break

		case actionTypes.SET_RECEIPT:
			Object.assign(newState, {
				value: {
					...newState.value,
					receipt: action.payload
				}
			})

			break

		case actionTypes.SET_RECEIPT_STATUS:
			Object.assign(newState, {
				value: {
					...newState.value,
					receiptStatus: action.payload
				}
			})

			break

		case actionTypes.SET_RECEIPT_NOTIFICATION:
			Object.assign(newState, {
				value: {
					...newState.value,
					receipt: {
						...newState.value.receipt,
						notification: action.payload
					}
				}
			})

			break

		case actionTypes.SET_COUPON:
			Object.assign(newState, {
				value: {
					...newState.value,
					coupon: action.payload
				}
			})

			break

		case actionTypes.SET_SELECTED_COUPON:
			Object.assign(newState, {
				value: {
					...newState.value,
					couponField: action.payload
				}
			})

			break

		case actionTypes.SET_COUPONS:
			Object.assign(newState, {
				value: {
					...newState.value,
					coupons: action.payload
				}
			})

			break

		case actionTypes.SET_ORDERS:
			Object.assign(newState, {
				value: {
					...newState.value,
					orders: action.payload
				}
			})

			break

		case actionTypes.SET_ORDER_HISTORY:
			Object.assign(newState, {
				value: {
					...newState.value,
					orderHistory: {
						...newState.value.orderHistory,
						...action.payload
					}
				}
			})

			break

		case actionTypes.SET_ORDER:
			Object.assign(newState, {
				value: {
					...newState.value,
					order: action.payload
				}
			})

			break

		case actionTypes.SET_ORDER_FINISH_DATA:
			Object.assign(newState, {
				value: {
					...newState.value,
					order: {
						...newState.value.order,
						finishData: {
							...newState.value.order.finishData,
							...action.payload
						}
					}
				}
			})

			break

		case actionTypes.SET_GTM:
			Object.assign(newState, {
				value: {
					...newState.value,
					gtm: action.payload
				}
			})

			break

		case actionTypes.SET_PARAMS:
			Object.assign(newState, {
				value: {
					...newState.value,
					params: {
						...newState.value.params,
						...action.payload
					}
				}
			})

			break

		case actionTypes.SET_URL_PARAMETERS:
			Object.assign(newState, {
				value: {
					...newState.value,
					URLParameters: action.payload
				}
			})

			break

		case actionTypes.SET_CARD:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: action.payload
				}
			})

			break

		case actionTypes.SET_SHOW_SIGN_UP:
			Object.assign(newState, {
				value: {
					...newState.value,
					showSignUp: action.payload
				}
			})

			break

		case actionTypes.SET_SIGN_IN:
			Object.assign(newState, {
				value: {
					...newState.value,
					login: action.payload
				}
			})

			break

		case actionTypes.SET_CARD_NAME:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						name: action.payload
					}
				}
			})

			break

		case actionTypes.SET_CARD_NUMBER:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						number: action.payload
					}
				}
			})

			break

		case actionTypes.SET_CARD_DATE:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						date: action.payload
					}
				}
			})

			break

		case actionTypes.SET_CARD_BIRTH:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						birth: action.payload
					}
				}
			})

			break


		case actionTypes.SET_CARD_CPF:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						CPF: action.payload
					}
				}
			})

			break

		case actionTypes.SET_CARD_BRAND_ID:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						brandId: action.payload
					}
				}
			})

			break

		case actionTypes.SET_CARD_CVV:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						CVV: action.payload
					}
				}
			})

			break

		case actionTypes.SET_ANTIFRAUD_CHECK:
			Object.assign(newState, {
				value: {
					...newState.value,
					antifraudCheck: action.payload
				}
			})

			break

		case actionTypes.SET_ANTIFRAUD:
			Object.assign(newState, {
				value: {
					...newState.value,
					order: {
						...newState.value.order,
						antifraud: action.payload
					}
				}
			})

			break

		default:
	}

	localStorage.setItem('main', JSON.stringify(newState.value))

	return newState.value
}

export default mainReducer
